.quote-form-gift-car-container {
  min-width: 300px;
}
.quote-form-gift-car-block {
  position: relative;
  border-radius: 2em;
  background-color: white;
  padding: 1em;
  padding-top: 3em;
}
.quote-form-gift-car {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -100%);
  height: 5em;
}

@media (min-width: 480px) {
  .quote-form-gift-car-block {
    padding: 2em;
  }
}

@media (min-width: 768px) {
  .quote-form-gift-car-block.floating {
    position: fixed;
  }
}

@media (max-height: 600px) {
  .quote-form-gift-car-block {
    padding-top: 1.5em;
    margin-top: 1.5em;
  }
  .quote-form-gift-car {
    height: 3em;
  }
}
