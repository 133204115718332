$primary: #ff6f00;
$info: #ff6f00;
$light-primary: #ffebe5;
$close-color: white;
$gunmetal: #424649;
$close-text-shadow: none;

html {
  font-size: 10px;
}

.longquote-container {
  max-width: 640;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  overflow-y: hidden;

  & .styles-module_Highlight__XzFkP {
    background-color: $light-primary;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin: 1em auto 2.5em auto;
    padding: 10px;
    border-radius: 10px;

    & svg {
      display: none;
    }

    @media (min-width: 574px) {
      padding: 30px;
      & svg {
        display: block;
        max-height: 100%;
        flex: 0 0 4em;
        margin-left: 2em;
        transform: scale(1.3);
      }
      & p {
        flex: 1;
      }
    }
  }

  #lonquote-anchor-vehicule {
    top: -230px !important; /* -100 -Highlight height Keep intro visible */
  }
}

// only use in intro rbs, modal overlay
.styles-module_Overlay__37xSj {
  background-color: rgba(41,48,51, 0.6);
}

.customization-summary {
  list-style-type: none;
  font-weight: bold;
  text-align: left;
  padding-left: 0.5em;
}

#customization-options {
  width: 100%;
  margin-top: 1.5em;
  text-align: left;

  & .styles-module_Highlight__XzFkP {
    margin-bottom: 0.5em;
  }
  & .styles-module_CheckboxContainer__cHddK {
    text-align: left;
  }
  &
    .styles-module_CheckboxContainer__cHddK
    + .styles-module_CheckboxContainer__cHddK {
    margin-top: 0.5em;
  }
  & .styles-module_Label__2Y2UM {
    display: flex;
    flex-grow: 1;
    & .option-label {
      flex-grow: 1;
    }
    & .option-price {
      margin-left: 0.5em;
      color: $primary;
      float: right;
    }
  }
  & .option-explication {
    display: block;
    margin-top: 0.8em;
    font-size: 0.7em;
    color: $gunmetal;
  }
  & .customization-checkbox-disabled + .customization-checkbox-disabled {
    margin-top: 1em;
  }
  & .customization-checkbox-disabled {
    & .styles-module_Checkbox__1wQ0F {
      background-color: #dae1e6 !important;
      border-color: #dae1e6 !important;
      &::after {
        opacity: 0 !important;
      }
    }
    & svg path {
      stroke: #fff !important;
    }
  }
}

#longquote-steps {
  display: none;
  position: absolute;
  border-radius: 1em;
  top: 200%;
  right: 5%;
  background-color: white;
  //border: 1px solid grey;
  padding: 30px;
  -webkit-box-shadow: 0 5px 20px 0 rgba(41, 48, 51, 0.1);
  box-shadow: 0 5px 20px 0 rgba(41, 48, 51, 0.1);
  & .styles-module_Item__2LDbx {
    font-weight: normal;
    font-size: 0.9em;
    padding: 10px;
    &:before,
    &:after {
      width: 5px;
    }
  }
  & .styles-module_IconContainer__LmtH9 {
    margin-right: 1.5em;
    & svg {
      height: 1em;
    }
  }
  /* TODO make this work
  .styles-module_Item__2LDbx.styles-module_ActiveItem__U57UD
    ~ .styles-module_Item__2LDbx::after {
    content: " ";
    height: 0%;
  }
  & .styles-module_VerticalItems__3jsyR .styles-module_Item__2LDbx:after,
  .styles-module_VerticalItems__3jsyR .styles-module_Item__2LDbx:before {
    transition: height 0.5s;
  } */
}
@media (min-width: 1180px) {
  #longquote-steps {
    display: block;
  }
}

.longquote-block-illustration {
  margin: 0;
  width: 2em;
  height: 2em;
  align-self: center;
}
@media (min-width: 574px) {
  .longquote-block-illustration {
    margin: 0 2em 0 1em;
    width: 4em;
    height: 4em;
  }
}

.styles-module_Popover__1-Shs {
  overflow-y: scroll;
  max-height: 25em; // 12 months
}

.question-transition-wrapper {
  position: relative;
  transition: all 0.5s;
  min-height: 80vh;
  height: auto;
  max-width: 90%;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 1em;
  margin: auto;
  margin-bottom: 2.5em;
}
.question-transition-wrapper.restituting {
  padding: 0.5em;
  & h3 {
    margin-top: 0.7em;
  }
  border: 1px solid white;
  -webkit-box-shadow: 0 5px 20px 0 rgba(41, 48, 51, 0.1);
  box-shadow: 0 5px 20px 0 rgba(41, 48, 51, 0.1);
  min-height: 9em;
}

.question-transition-base,
.question-transition-enter,
.question-transition-exit,
.question-transition-enter-done,
.question-transition-exit-done,
.question-transition-reverse-enter,
.question-transition-reverse-exit,
.question-transition-reverse-enter-done,
.question-transition-reverse-exit-done {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.5s;
}
@media (max-width: 574px) {
  .question-transition-base,
  .question-transition-enter,
  .question-transition-exit,
  .question-transition-enter-done,
  .question-transition-exit-done,
  .question-transition-reverse-enter,
  .question-transition-reverse-exit,
  .question-transition-reverse-enter-done,
  .question-transition-reverse-exit-done {
    padding: 0.5em;
  }
}
.question-transition-enter {
  transform: translateX(100%);
  opacity: 0;
}
.question-transition-enter-active {
  transform: translateX(0);
  opacity: 1;
}
.question-transition-exit {
  transform: translateX(0);
  opacity: 1;
}
.question-transition-exit-active {
  transform: translateX(-100%);
  opacity: 0;
}

.question-transition-reverse-enter {
  transform: translateX(-100%);
  opacity: 0;
}
.question-transition-reverse-enter-active {
  transform: translateX(0);
  opacity: 1;
}
.question-transition-reverse-exit {
  transform: translateX(0);
  opacity: 1;
}
.question-transition-reverse-exit-active {
  transform: translateX(100%);
  opacity: 0;
}

.longquote-cta-container {
  margin: 2em 5% 5em 5%;
  text-align: center;
  position: relative;
}

.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
  background-color: $primary !important;
}

.question-button {
  &.styles-module_Stretch__2ROFw {
    width: 95%; // 100% / 1.05; no exceed when transform 1.05
    margin-left: 2.5%;
    margin-right: 2.5%;
    text-align: center;
    & > .styles-module_Content__oxWk7 {
      text-align: inherit;
      -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
      justify-content: inherit;
    }
  }
  &.styles-module_Accent__3MXDT {
    background: #fff;
    color: #293033;
    border: 1px solid #91a4ae;
  }
  &.styles-module_Accent__3MXDT.kitt-active,
  &.styles-module_Accent__3MXDT.kitt-hover,
  &.styles-module_Accent__3MXDT:active,
  &.styles-module_Accent__3MXDT:hover {
    background: #fff;
    border-color: #ff9e7f;
  }
  &.styles-module_Accent__3MXDT.kitt-focus,
  &.styles-module_Accent__3MXDT:focus {
    border-color: #ff9e7f;
  }
  & > .styles-module_Content__oxWk7 {
    font-weight: 400;
    & strong {
      color: $primary;
      font-weight: 700;
    }
  }
}

.slider-component {
  position: relative;
  height: 30px;
  margin-top: 80px;
  margin-bottom: 40px;
  & .thumb {
    height: 30px;
    width: 30px;
    background-color: $primary;
    border-radius: 100%;
    border: 5px solid white;
    transition: border 0.3s;
    &:focus,
    &:hover {
      outline: none;
      border: 5px solid #ffc7b4;
    }
  }
  & .thumb-tooltip {
    position: absolute;
    width: auto;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 30px;
    background-color: #424649;
    padding: 10px 10px 13px;
    display: block;
    font-size: 18px;
    color: #fff;
    min-width: 120px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    transition: transform 0.3s ease;
    &::after {
      content: " km";
    }
    &::before {
      transition: left 0.3s ease;
      content: " ";
      width: 10px;
      height: 10px;
      position: absolute;
      left: calc(50% - 10px);
      bottom: -12px;
      width: 0;
      height: 0;
      border-color: #424649 transparent transparent;
      border-style: solid;
      border-width: 12px 10px 0;
    }
  }
  & .track {
    top: 13px;
    height: 4px;
    background-color: #ff9e7f;
  }
}

#Qalready .question-body,
#Qalreadycar .question-body,
#Qlicense .question-body {
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  justify-content: space-around;
}

.no-pricing-reason {
  font-weight: bold;
  color: $primary;
}

.styles-module_DefaultStyling__jYFJ9 {
  padding: 0px 0px 10px 0;
}
/*
#Qalready .question-button,
#Qalreadycar .question-button,
#Qlicense .question-button {
  position: relative;
  display: inline-block !important;
  width: 40% !important;
  margin: 0 5% !important;
  padding: 1em !important;
  &::after {
    content: " ";
    display: block;
    width: 100%;
    padding-top: 100%;
    background-color: blue;
  }
}*/

.styles-module_TextArea__2BKDI {
  resize: none !important;
}

.styles-module_Nav__IYROW {
  align-items: center;
}
.styles-module_Nav__IYROW.bmenuNav {
  display: block;
}

// always open autocompletes
#Qmodel,
#Qmalus,
#Qprincipal_malus,
#Qsecondary_malus,
#QcurrentPrice,
.Qautocomplete {
  .styles-module_Results__14ogX {
    height: 18em;
    overflow-y: auto;
    box-shadow: none;
    border: 2px solid #eceff1;
    height: 10em;
  }
}

.question {
  display: flex;
  flex-direction: column;
}
.question-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#Qwhen,
#Qstill {
  .styles-module_Content__oxWk7 {
    justify-content: left;
  }
}

@media (min-width: 574px) {
  .styles-module_Nav__IYROW.bmenuNav {
    display: none;
  }
}

.Title-uByUsGR7aM5OH2Ex7hYEW {
  // .Title-3xkWvilmBzu2kFIjI3brsd {
  max-width: 450px;
  margin: 0 auto 16px;
  & strong {
    color: $primary;
  }
}
@media (max-height: 600px) {
  .Title-uByUsGR7aM5OH2Ex7hYEW {
    font-size: 2rem;
  }
  #quote-wrap {
    padding-top: 1rem;
  }
  .question {
    min-height: fit-content !important;
  }
}
@media (min-width: 768px) {
  .Title-uByUsGR7aM5OH2Ex7hYEW {
    // .Title-3xkWvilmBzu2kFIjI3brsd {
    margin-bottom: 20px;
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  .Title-3xkWvilmBzu2kFIjI3brsd {
    max-width: 1060px;
    margin-bottom: 16px;
  }
}

.FirstPart-iB2HjDqgXN1JOZP5-bMGQ strong {
  color: $primary;
}
.TheoryHeroContent-2KpY4BzVK80jMQM5IFq3Pe {
  text-align: center;
  margin-bottom: 1em;
  @media (min-width: 768px) {
    & {
      text-align: left;
    }
  }
}

#quote-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  padding: 15px;
}

#quote-hero {
  width: 100%;
  margin-bottom: 4em;
  text-align: center;
}
#quote-form {
  width: 100%;
  margin-bottom: 3em;
}
.show-small {
  display: block;
}
.hide-small {
  display: none;
}

#progress-bar {
  margin-top: 1.5em;
}
#progress-bar-longquote-container {
  position: fixed;
  top: 0;
  width: 100%;
  margin: auto;
  max-width: 640px;
  background-color: white;
  height: 100px;
  z-index: 2;
}
#progress-bar-longquote {
  width: 90% !important;
  margin: 0 5%;
  position: absolute;
  bottom: 0;
}

.quote-form-header {
  margin-top: 0.5em;
}
.quote-form-cute-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: center;
  padding: 2em .5em 0em .5em;
  height: 9em;
}

.styles_PageContainer__1H8Ki {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.styles_Content__3heCT {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}
.styles_Text__3Sn1V {
  text-align: justify;
  margin: 20px 0 0;
}
.styles_Description__zIzcq {
  text-align: inherit;
}
.styles_ContentContainer__1gz-m {
  height: fit-content;
  // align-self: flex-start;
}
.styles_Reassurance__2yZjY {
  margin-top: 2em;
}
.styles_Logos__hqaJm {
  max-width: none;
  justify-content: center;
}
.styles_Content__3heCT {
  margin-bottom: 3em;
}
@media (min-width: 768px) {
  .styles_PageContainer__1H8Ki {
    flex-direction: row;
    align-items: stretch;
    &.customization {
      align-items: flex-start;
    }
  }
  .styles_ContentContainer__1gz-m {
    margin-right: 3em;
    height: auto;
    display: flex;
    text-align: left;
  }
  .styles_Description__zIzcq {
    margin: 1em 0px;
  }
  .styles_Options__RHGIO {
    margin: 0px;
  }
  .styles_Content__3heCT {
    text-align: left;
    height: auto;
    align-items: self-start;
    margin: 0 auto;
    flex: 1 0 100%;
  }
  .styles_Logos__hqaJm {
    justify-content: flex-start;
  }
}

@media (max-height: 600px) {
  #quote-wrap {
    padding-top: 1rem;
  }
  .styles_Page__Y4-j3 {
    padding-top: 1rem;
  }
}

@media (max-width: 768px) {
  .styles_Card__prtwC {
    padding: 20px !important;
  }
}

@media (min-width: 768px) {
  .show-small {
    display: none !important;
  }
  .hide-small {
    display: block;
  }
  #quote-wrap {
    padding: 4em 30px 30px 30px;
  }
  #quote-hero {
    text-align: left;
    margin-bottom: 0;
    width: auto;
    flex: 1 0 48%;
    margin-top: 1em;
  }
  #quote-form {
    position: relative;
    flex: 1 0 50%;
    max-width: 600px;
  }
}
@media (min-width: 1440px) {
  #quote-wrap {
    width: 1280px;
    margin: 0 auto !important;
    padding: 4em 0 30px 0;
  }
}

#Qbrand .buttonsContainer,
#QcurrentAssu .buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2em 1% 0 1%;
}
#Qbrand .brandButton,
#QcurrentAssu .brandButton {
  flex: 0 0 31%;
  justify-self: center;
  padding: 3px;
  margin: 0 1% 1% 1%;
  &.dropOne {
    display: none;
  }
}

#Qbrand .buttonImg,
#QcurrentAssu .buttonImg {
  align-self: center;
  max-height: 100%;
  height: auto;
  width: 100%;
}

.ProductFeatures-10hB5d3bAkz7htQsLgDIno {
  flex-direction: column;
}

@media (min-width: 480px) {
  #Qbrand .brandButton,
  #QcurrentAssu .brandButton {
    flex-basis: 23%;
    &.dropOne {
      display: block;
    }
    &.dropTwo {
      display: none;
    }
  }
}
@media (min-width: 1024px) {
  #Qbrand .brandButton,
  #QcurrentAssu .brandButton {
    flex-basis: 18%;
    &.dropTwo {
      display: block;
    }
  }
}

@media (min-width: 1024px) {
  .styles-types-module_large-header1__Xl4o8 {
    font-size: 5rem;
  }
}

span.avoidwrap {
  display: inline-block;
}

// HEADER OVER MAIN
// match .styles-module_Content__1X_9b
#top {
  margin-top: 60px;
}
.anchor {
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute !important;
  top: -65px !important;
}

@media (min-width: 480px) {
  #top {
    margin-top: 80px;
  }
  .anchor {
    top: -120px !important;
  }
}

.styles_Action__1Gs6J {
  margin-top: 2em;
}

.ProductNameSecondPart-3Zh_3_nWrQQSwdCXV9RVT_ strong {
  color: $primary;
}

// @TODO: change images
.France3Svg-aDTBRgGouPdzy30rFj-oJ {
  width: 37px;
  height: 32px;
}

.BfmSvg-1yVo94m9xpPjHW1kiOQBGl {
  width: 37px;
  height: 37px;
}
.OuestFranceSvg-u_msRh2KKGVF1OZvDytTF {
  width: 93px;
  height: 32px;
}

@media (min-width: 1024px) {
  .France3Item-BzCJauMiIFoJB_LJaoGA1 {
    max-width: 310px;
  }
  .France3Item-BzCJauMiIFoJB_LJaoGA1 img {
    margin: 22px auto 0;
  }
  .OuestFranceItem-qljbEcCb91gVjSWybUkdQ {
    max-width: 290px;
  }
  .OuestFranceItem-qljbEcCb91gVjSWybUkdQ img {
    margin: 26px auto 0;
  }
  .BfmItem-JgvG06LaBAqMQeGqh5b8A {
    max-width: 290px;
    padding: 0 45px;
  }
  .BfmItem-JgvG06LaBAqMQeGqh5b8A img {
    margin: 26px auto 0;
  }
}

@media (min-width: 768px) {
  .BfmSvg-1yVo94m9xpPjHW1kiOQBGl {
    width: 60px;
    height: 60px;
  }
  .France3Svg-aDTBRgGouPdzy30rFj-oJ {
    width: 59px;
    height: 50px;
  }
  .OuestFranceSvg-u_msRh2KKGVF1OZvDytTF {
    width: 147px;
    height: 50px;
  }
}
