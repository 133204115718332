#cute-price {
  display: flex;
  flex-direction: row;
  color: #ff6f00;
  max-width: 100%;
  max-width: 100%;
  margin: auto;
  width: 3.3em;
  font-size: 3em;
  @media (min-width: 350px) {
    font-size: 4em;
  }
  @media (min-width: 480px) {
    font-size: 5em;
  }
  &.more99 {
    font-size: 2em;
    @media (min-width: 350px) {
      font-size: 3em;
    }
    @media (min-width: 480px) {
      font-size: 4em;
    }
  }
}
