@font-face {
  font-family: Geomanist;
  src: url(../assets/fonts/9389e5aa1f43e7b1cd9f7b13937eeae0.woff2)
      format("woff2"),
    url(../assets/fonts/6979a6ba190ec50ae364ed314cae7842.woff) format("woff");
  font-weight: 700;
  font-display: block;
}

@font-face {
  font-family: Geomanist;
  src: url(../assets/fonts/823ad4b07a1054e9f13021e3df8d8e2c.woff2)
      format("woff2"),
    url(../assets/fonts/ae86916d81ac6bb85bcd5ac8e7da5fff.woff) format("woff");
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: GeomanistHinted;
  src: url(../assets/fonts/4aa3124af232958f758020c843e25821.woff2)
      format("woff2"),
    url(../assets/fonts/dfbeb0441261cd637f1ac650092251e3.woff) format("woff");
  font-weight: 700;
  font-display: block;
}

@font-face {
  font-family: GeomanistHinted;
  src: url(../assets/fonts/4743d4c551199fe7b278801cf21e8fd2.woff2)
      format("woff2"),
    url(../assets/fonts/65f152a73fe54f6db9a062721ef03404.woff) format("woff");
  font-weight: 400;
  font-display: block;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Geomanist, GeomanistHinted, sans-serif;
}

body[data-osused="windows"] {
  font-family: GeomanistHinted, sans-serif;
}

body[data-osused="other"] {
  font-family: Geomanist, sans-serif;
}

.styles-module_Logo__33vY1 {
  fill: #293033;
  width: auto;
}

.styles-module_Header__3jJsx {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 5px 20px 0 rgba(41, 48, 51, 0.1);
  box-shadow: 0 5px 20px 0 rgba(41, 48, 51, 0.1);
}

.styles-module_Header__3jJsx + * {
  margin-top: 100px;
}

.styles-module_Content__1X_9b {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: auto;
  z-index: 1;
  padding: 10px;
  min-height: 60px;
}

@media (min-width: 480px) {
  .styles-module_Content__1X_9b {
    padding: 20px;
    min-height: 80px;
  }
}

@media (min-width: 768px) {
  .styles-module_Content__1X_9b {
    max-width: 1440px;
    position: relative;
    border-bottom: none;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1280px) {
  .styles-module_Content__1X_9b {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.styles-module_Logo__2EP3X {
  display: block;
  height: 22px;
  -webkit-transform: translate(10px, -1px);
  transform: translate(10px, -1px);
}

@media (min-width: 480px) {
  .styles-module_Logo__2EP3X {
    height: 28px;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}

.styles-module_Nav__IYROW {
  background: #fff;
  position: relative;
  width: 100%;
}

@media (min-width: 574px) {
  .styles-module_Nav__IYROW {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

@media (min-width: 574px) {
  .styles-module_Nav__IYROW > :not(:last-child) {
    margin-right: 40px;
  }
}

@media (min-width: 574px) {
  .styles-module_FullLink__18nCs {
    display: none;
  }
}

@media (min-width: 768px) {
  .styles-module_FullLink__18nCs {
    display: inline;
  }
}

.styles-module_Medium__2v-qB {
  display: none;
}

@media (min-width: 574px) {
  .styles-module_Medium__2v-qB {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    top: 0;
    width: auto;
  }
}

.styles-module_Item__2p_GN {
  display: block;
  padding: 44px 10px 17px;
  text-align: center;
}

@media (min-width: 574px) {
  .styles-module_Item__2p_GN {
    padding: 0;
  }
}

.styles-module_Button__2zcg9 {
  margin-top: 38px;
  padding: 30px 20px;
  border-top: 1px solid #eceff1;
}

@media (min-width: 574px) {
  .styles-module_Button__2zcg9 {
    margin: 0;
    padding: 0;
    border: none;
    display: inline-block;
  }
}

.styles-module_BurgerButton__FXQbB {
  display: inline-block;
}

@media (min-width: 574px) {
  .styles-module_BurgerButton__FXQbB {
    display: none;
  }
}

.styles-module_LogoWhite__QFIzj {
  fill: #fff;
}

.Logo-QDJCzqWK8fd7hsS62E_pg {
  height: 24px;
}

@media (min-width: 480px) {
  .Logo-QDJCzqWK8fd7hsS62E_pg {
    height: 35px;
  }
}

.Footer-3frVEcyx5nx0v22H_KVG96 {
  background: #293033;
  padding: 0 20px 40px;
}

@media (min-width: 768px) {
  .Footer-3frVEcyx5nx0v22H_KVG96 {
    padding: 0 40px 40px;
  }
}

@media (min-width: 1024px) {
  .Footer-3frVEcyx5nx0v22H_KVG96 {
    padding: 0 80px 30px;
  }
}

.FooterContent-31y0MgmGnXCOijgpMETYUF {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 40px 0 0;
}

@media (min-width: 768px) {
  .FooterContent-31y0MgmGnXCOijgpMETYUF {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .FooterContent-31y0MgmGnXCOijgpMETYUF {
    padding: 40px 0 0;
    max-width: 1280px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.FirstPart-iB2HjDqgXN1JOZP5-bMGQ {
  text-align: center;
}

@media (min-width: 1024px) {
  .FirstPart-iB2HjDqgXN1JOZP5-bMGQ {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 290px;
    flex: 0 0 290px;
    text-align: left;
  }
}

.SecondPart-1JMtWmT3kbO5q14n448_no {
  margin-top: 10px;
}

@media (min-width: 1024px) {
  .SecondPart-1JMtWmT3kbO5q14n448_no {
    margin-top: 16px;
    -webkit-box-flex: 1;
    -ms-flex: 1 0;
    flex: 1 0;
  }
}

.FooterLinks-3wmwKbeZMtWFP-fWlqgVj9 {
  text-align: center;
}

@media (min-width: 1024px) {
  .FooterLinks-3wmwKbeZMtWFP-fWlqgVj9 {
    text-align: right;
  }
}

.TrustedShop-2_9QdGR-S2p8XWx_aPRJEu {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.TrustedShop-2_9QdGR-S2p8XWx_aPRJEu > * {
  margin: 0 5px 10px;
  text-align: center;
}

@media (min-width: 1024px) {
  .TrustedShop-2_9QdGR-S2p8XWx_aPRJEu {
    margin-top: 25px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .TrustedShop-2_9QdGR-S2p8XWx_aPRJEu > * {
    margin: 0 0 10px;
  }

  .TrustedShop-2_9QdGR-S2p8XWx_aPRJEu .Rating-1ojzz-Iis2jc9n_2CwCVI- {
    margin-right: 10px;
  }
}

.Legal-3JczryTdyBhRc3ZHb96zEf {
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 1024px) {
  .Legal-3JczryTdyBhRc3ZHb96zEf {
    max-width: 1280px;
  }
}

.FooterLegal-1TGwyAR4yV_C_9WvZyI6OG {
  margin-top: 60px;
}

.Item-2T2OtotkqBzhiOGr0_1mDe {
  display: inline-block;
  white-space: nowrap;
}

.Item-2T2OtotkqBzhiOGr0_1mDe:not(:first-child) {
  margin-left: 9px;
}

.Item-2T2OtotkqBzhiOGr0_1mDe:not(:first-child):before {
  content: "\2022";
  margin-right: 8px;
  color: #38454c;
}

.BlockOnMobile-WEOQ7T3d3ccgtKsAjnBQX .AllowBreak-351iDo2A_NMAuqs8sv7WcV {
  display: none;
}

@media (min-width: 768px) {
  .BlockOnMobile-WEOQ7T3d3ccgtKsAjnBQX .AllowBreak-351iDo2A_NMAuqs8sv7WcV {
    display: inline;
  }
}

.BlockOnMobile-WEOQ7T3d3ccgtKsAjnBQX .Item-2T2OtotkqBzhiOGr0_1mDe {
  margin-bottom: 5px;
  display: block;
}

.BlockOnMobile-WEOQ7T3d3ccgtKsAjnBQX
  .Item-2T2OtotkqBzhiOGr0_1mDe:not(:first-child) {
  margin-left: 0;
}

.BlockOnMobile-WEOQ7T3d3ccgtKsAjnBQX .Item-2T2OtotkqBzhiOGr0_1mDe:before {
  display: none;
}

@media (min-width: 768px) {
  .BlockOnMobile-WEOQ7T3d3ccgtKsAjnBQX .Item-2T2OtotkqBzhiOGr0_1mDe {
    display: inline;
    /* @TODO uncomment ? margin-bottom: 0;*/
  }

  .BlockOnMobile-WEOQ7T3d3ccgtKsAjnBQX
    .Item-2T2OtotkqBzhiOGr0_1mDe:not(:first-child) {
    margin-left: 9px;
  }

  .BlockOnMobile-WEOQ7T3d3ccgtKsAjnBQX .Item-2T2OtotkqBzhiOGr0_1mDe:before {
    display: inline;
  }
}

.Social-3ls63_lvdk-Mn85MSzjvI- {
  text-align: center;
  margin-top: 25px;
}

.Social-3ls63_lvdk-Mn85MSzjvI- > * {
  height: 25px;
}

.Social-3ls63_lvdk-Mn85MSzjvI- img {
  height: 25px;
  width: auto;
}

.Social-3ls63_lvdk-Mn85MSzjvI- > :not(:last-child) {
  margin-right: 25px;
}

@media (min-width: 1024px) {
  .Social-3ls63_lvdk-Mn85MSzjvI- {
    text-align: right;
  }
}

.Rating-2YtL-a1AN57P9tbSwRox0C {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  height: 32px;
  width: 210px;
}

.Rating-2YtL-a1AN57P9tbSwRox0C svg {
  height: 32px;
  width: 34px;
  vertical-align: top;
}

@media (min-width: 768px) {
  .Rating-2YtL-a1AN57P9tbSwRox0C {
    height: 50px;
    width: 320px;
  }

  .Rating-2YtL-a1AN57P9tbSwRox0C svg {
    height: 50px;
    width: 52px;
  }
}

.EmptyStars-3A8RTh7rK8fRDo2NbzO9Ti {
  direction: rtl;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.EmptyStars-3A8RTh7rK8fRDo2NbzO9Ti svg {
  fill: #485960;
}

.EmptyStars-3A8RTh7rK8fRDo2NbzO9Ti svg:not(:last-child) {
  margin-left: 10px;
}

@media (min-width: 768px) {
  .EmptyStars-3A8RTh7rK8fRDo2NbzO9Ti svg:not(:last-child) {
    margin-left: 15px;
  }
}

.FullStars-38MAqhE3vPJNl5EIsA2GOe {
  direction: ltr;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.FullStars-38MAqhE3vPJNl5EIsA2GOe svg {
  fill: #ffab00;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.FullStars-38MAqhE3vPJNl5EIsA2GOe svg:not(:last-child) {
  margin-right: 10px;
}

@media (min-width: 768px) {
  .FullStars-38MAqhE3vPJNl5EIsA2GOe svg:not(:last-child) {
    margin-right: 15px;
  }
}

.Small-2N7CzObSSe2qLT1HZi-IHM {
  height: 15px;
  width: 96px;
}

.Small-2N7CzObSSe2qLT1HZi-IHM svg {
  height: 15px;
  width: 16px;
}

.Small-2N7CzObSSe2qLT1HZi-IHM
  .EmptyStars-3A8RTh7rK8fRDo2NbzO9Ti
  svg:not(:last-child) {
  margin-left: 4px;
}

.Small-2N7CzObSSe2qLT1HZi-IHM
  .FullStars-38MAqhE3vPJNl5EIsA2GOe
  svg:not(:last-child) {
  margin-right: 4px;
}








/* MAIN */
/*! CSS Used from: https://www.ornikar.com/_next/static/css/commons.6fcedd44.chunk.css */
.Separator-2iDYalYlAa5fh40Eknasko {
  background-color: #dae1e6;
  border: none !important;
  overflow: hidden;
  height: 1px;
  margin: 0 20px;
}
@media (min-width: 768px) {
  .Separator-2iDYalYlAa5fh40Eknasko {
    margin: 0 40px;
  }
}
@media (min-width: 1024px) {
  .Separator-2iDYalYlAa5fh40Eknasko {
    margin: 0 80px;
  }
}
@media (min-width: 1440px) {
  .Separator-2iDYalYlAa5fh40Eknasko {
    width: 1280px;
    margin: 0 auto;
  }
}
.PageContentSeparator-mGjj5acWlVciFI_yMhL_e {
  margin-top: 50px;
  margin-bottom: 50px;
}
/* UNUSED
.HideOnLargeAndUp-2kX18zPeonrol8gcItaqlc {
  display: block;
}
@media (min-width: 1024px) {
  .HideOnLargeAndUp-2kX18zPeonrol8gcItaqlc {
    display: none;
  }
}*/
.EmptyColorWhite-1CtmmU1zOnnh-LE5IaV2il .EmptyStars-3A8RTh7rK8fRDo2NbzO9Ti svg {
  fill: #eceff1;
}
.Illustration-RwVUE4CPDJBUikHaC-DTa {
  display: block;
  height: 100%;
  margin: 0 auto;
}
.Container-1UqTp-95VKx05XHwXfwFTr {
  position: relative;
  height: 100%;
  background-color: #fafbfc;
}
.BlazeCoquelicot-24niov24y_qtmJpKM-gM3k {
  background-image: -webkit-linear-gradient(315deg, #ff6f00, #ff3d00);
  background-image: -o-linear-gradient(315deg, #ff6f00 0, #ff3d00 100%);
  background-image: linear-gradient(-225deg, #ff6f00, #ff3d00);
}
.Content-una4U27L41Wj1vtLcFnsN {
  position: relative;
}
.PatternContainer-33uUR6xEhxN6P_6slSRlSK {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}
.Transparent-CLhlWFmNKZnheJepwnCwV {
  opacity: 0.1;
}

/* @TODO fix me URL ornikar.com */
.Pattern-1quShsxvCruP5wl3_ckk4x {
  background: url(https://www.ornikar.com/_next/static/assets/9c7b035097ecf3cd2374aa70f0d8109f.svg)
    repeat;
  background-size: 3943px 2150px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .Pattern-1quShsxvCruP5wl3_ckk4x {
    background: 0 0;
  }
}
.InfoTooltipContainer-2lPWA5Crh5ubMxKZWiR0VL {
  font-size: 20px;
  height: 20px;
  line-height: 25px;
}
.Reviews-2C8DHS3yGNJ-1OVgKzMcYt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .Reviews-2C8DHS3yGNJ-1OVgKzMcYt {
    padding: 0 40px;
  }
}
@media (min-width: 1024px) {
  .Reviews-2C8DHS3yGNJ-1OVgKzMcYt {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1280px;
    padding: 0 110px;
    margin: 0 auto;
  }
}
.RatingContainer-oGMzz_4E_MNGI6pIpqJax {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
@media (min-width: 1024px) {
  .RatingContainer-oGMzz_4E_MNGI6pIpqJax {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 400px;
    margin-right: 20px;
  }
}
.Rating-3h--YWCWtPWI-uuQZr8IQf {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .Rating-3h--YWCWtPWI-uuQZr8IQf {
    margin-top: 15px;
  }
}
.RatingDetails-1mxs7nqYlZCVIBBdMTGeUu {
  margin-top: 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .RatingDetails-1mxs7nqYlZCVIBBdMTGeUu {
    margin-top: 35px;
    text-align: left;
  }
}
.RatingSource-3d_f2LUkurah5Io--RssNT {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .RatingSource-3d_f2LUkurah5Io--RssNT {
    margin-top: 45px;
  }
}
.Testimonial-3LFChE7dMJyfl7mBNIKx-n {
  margin-top: 50px;
}
@media (min-width: 768px) {
  .Testimonial-3LFChE7dMJyfl7mBNIKx-n {
    max-width: 688px;
    margin: 50px auto 0;
  }
}
@media (min-width: 1024px) {
  .Testimonial-3LFChE7dMJyfl7mBNIKx-n {
    max-width: 510px;
    margin: 0;
  }
}
.MainProductsSection-3NHtNRJ9HurvwPwAefi39b {
  margin-top: 50px;
  text-align: center;
}
@media (min-width: 1024px) {
  .MainProductsSection-3NHtNRJ9HurvwPwAefi39b:before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100px;
    content: "";
    display: block;
    background-color: #fff;
  }
}
@media (min-width: 768px) {
  .MainProductsSection-3NHtNRJ9HurvwPwAefi39b {
    margin-top: 70px;
  }
}
@media (min-width: 1024px) {
  .MainProductsSection-3NHtNRJ9HurvwPwAefi39b {
    margin-top: 100px;
  }
}
.MainProducts-2nmkCRFGAFAkZa6eyvZTkI {
  position: relative;
}
@media (min-width: 1024px) {
  .MainProducts-2nmkCRFGAFAkZa6eyvZTkI {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 110px 80px;
  }
}
.Testimonial-EcyC9QFzmCo5drptIph1t {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
@media (min-width: 1024px) {
  .Testimonial-EcyC9QFzmCo5drptIph1t {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }
}
.Testimonial-EcyC9QFzmCo5drptIph1t cite {
  font-style: normal;
}
.AuthorQuote-3gkYgqgUxYs-6YnCCdJohw {
  width: 100%;
}
.Author-3AgP-tRKNlzv5yhsoax9cG {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
@media (min-width: 1024px) {
  .Author-3AgP-tRKNlzv5yhsoax9cG {
    margin-top: 35px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.AvatarContainer-1acruv5Qw2qInxyUq8qWhD {
  height: 60px;
  width: 60px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.AvatarContainer-1acruv5Qw2qInxyUq8qWhD > :first-child,
.AvatarContainer-1acruv5Qw2qInxyUq8qWhD img {
  border-radius: 20px;
}
@media (min-width: 768px) {
  .AvatarContainer-1acruv5Qw2qInxyUq8qWhD {
    height: 70px;
    width: 70px;
  }
}
.Product-3W3oEvT4Mg63oUrtd8-EEE {
  padding: 53px 0;
}
@media (min-width: 768px) {
  .Product-3W3oEvT4Mg63oUrtd8-EEE {
    padding: 70px 0;
  }
}
@media (min-width: 1024px) {
  .Product-3W3oEvT4Mg63oUrtd8-EEE {
    padding: 0;
    min-width: 400px;
  }
}
.ProductImage-3f_1994l1GFLRDOQpfs4Zo {
  height: 128px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .ProductImage-3f_1994l1GFLRDOQpfs4Zo {
    height: 160px;
    margin-bottom: 13px;
  }
}
@media (min-width: 1024px) {
  .ProductImage-3f_1994l1GFLRDOQpfs4Zo {
    height: 200px;
    margin-bottom: 10px;
  }
}
.ProductName-a0U7BbA-Mky7zP8ZQYJwG {
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .ProductName-a0U7BbA-Mky7zP8ZQYJwG {
    margin-bottom: 10px;
  }
}
@media (min-width: 1024px) {
  .ProductName-a0U7BbA-Mky7zP8ZQYJwG {
    margin-bottom: 14px;
  }
}
@media (min-width: 1024px) {
  .ProductNameSecondPart-3Zh_3_nWrQQSwdCXV9RVT_ {
    display: block;
  }
}
@media (min-width: 768px) {
  .ProductFeatures-10hB5d3bAkz7htQsLgDIno {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (min-width: 1024px) {
  .ProductFeatures-10hB5d3bAkz7htQsLgDIno {
    display: block;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .ProductCTA-2U9HS7NCqrs3V13lO4-ate {
    margin-top: 7px;
  }
}
@media (min-width: 1024px) {
  .ProductCTA-2U9HS7NCqrs3V13lO4-ate {
    margin-top: 0;
  }
}
.ProductPricing-3IwlPq4qaCKUsZn29lg8c3 {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .ProductPricing-3IwlPq4qaCKUsZn29lg8c3 {
    margin-right: 25px;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .ProductPricing-3IwlPq4qaCKUsZn29lg8c3 {
    margin-right: 0;
    margin-bottom: 0;
  }
}
.ProductClaim-3Kx8n4RKtoAQH3_HKPIFdw {
  color: #657884;
}
@media (min-width: 768px) {
  .ProductClaim-3Kx8n4RKtoAQH3_HKPIFdw {
    margin-top: 3px;
  }
}
@media (min-width: 1024px) {
  .ProductClaim-3Kx8n4RKtoAQH3_HKPIFdw {
    margin-bottom: 25px;
    margin-top: 0;
  }
}
.ProductTooltip-9BTXTjOYPpT-MHF4AbvUu {
  margin-left: 5px;
}
.AuthorDetails-2mEvbPDZTWc2E2ubMUBwan {
  margin-top: 10px;
}
@media (min-width: 1024px) {
  .AuthorDetails-2mEvbPDZTWc2E2ubMUBwan {
    margin: 0 0 0 20px;
  }
}
@media (min-width: 1024px) {
  .AuthorName-2vAHG-nUjpZI0HWuEdp9MP {
    padding-right: 18px;
  }
}
.AuthorRating-3L854_unuigHd_U1BCYrQI {
  margin-top: 10px;
}
@media (min-width: 1024px) {
  .AuthorRating-3L854_unuigHd_U1BCYrQI {
    margin-top: 12px;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
}
.DrivingHours-9Qtnu1vsCVlwDPINhuYoN {
  padding: 50px 20px;
}
@media (min-width: 768px) {
  .DrivingHours-9Qtnu1vsCVlwDPINhuYoN {
    padding: 50px 0;
  }
}
@media (min-width: 1024px) {
  .DrivingHours-9Qtnu1vsCVlwDPINhuYoN {
    padding: 30px;
  }
}
.DrivingHours-9Qtnu1vsCVlwDPINhuYoN a {
  margin-top: 20px;
  display: block;
}
@media (min-width: 1024px) {
  .DrivingHours-9Qtnu1vsCVlwDPINhuYoN a {
    display: inline;
    margin-left: 30px;
  }
}





/*! CSS Used from: https://www.ornikar.com/_next/static/css/styles.9988d88d.chunk.css */
/* UNUSED
.Title-3qDH3s-DS5NZu9cwIh1zmn {
  max-width: 320px;
  margin: auto;
  display: block;
}
@media (min-width: 768px) {
  .Title-3qDH3s-DS5NZu9cwIh1zmn {
    max-width: 100%;
  }
}
*/
@media (min-width: 768px) {
  .TheoryHeroContent-2KpY4BzVK80jMQM5IFq3Pe {
    max-width: 523px;
    margin: 0 auto;
  }
}
@media (min-width: 820px) {
  .TheoryHeroContent-2KpY4BzVK80jMQM5IFq3Pe {
    max-width: none;
    margin: 0;
  }
}
/* UNUSED
@media (min-width: 820px) {
  .Tooltip-2rN1O36lUtgEty7_YAz88g {
    display: inline-block;
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
}
.ProductHero-1lP5PQ4lUqGSPqg0oqHAvU {
  position: relative;
  text-align: center;
}
.Container-3-Lp8r6wprc-CVQsFxI7mK {
  padding: 10px 20px 0;
  background: #fff;
}
@media (min-width: 768px) {
  .Container-3-Lp8r6wprc-CVQsFxI7mK {
    padding: 10px 40px 0;
  }
}
.Illustration-3R23qiuUVT90tsJZuB3ZP3 {
  margin: 25px 0;
  height: 140px;
}
@media (min-width: 768px) {
  .Illustration-3R23qiuUVT90tsJZuB3ZP3 {
    margin-top: 0;
    height: 280px;
  }
}
@media (min-width: 1024px) {
  .Illustration-3R23qiuUVT90tsJZuB3ZP3 {
    margin-bottom: 40px;
    height: 310px;
  }
}*/
.Title-3xkWvilmBzu2kFIjI3brsd {
  max-width: 335px;
  margin: 0 auto 16px;
}
@media (min-width: 768px) {
  .Title-3xkWvilmBzu2kFIjI3brsd {
    margin-bottom: 20px;
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  .Title-3xkWvilmBzu2kFIjI3brsd {
    max-width: 1060px;
    margin-bottom: 16px;
  }
}
.Title-3xkWvilmBzu2kFIjI3brsd > :first-child {
  margin-bottom: 5px;
}
@media (min-width: 1024px) {
  .Title-3xkWvilmBzu2kFIjI3brsd > :first-child {
    margin-bottom: 0;
  }
}
.Content-2d3WHvJGiyEp-zrHkSJm6E {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .Content-2d3WHvJGiyEp-zrHkSJm6E {
    margin-bottom: 32px;
  }
}
@media (min-width: 1024px) {
  .Content-2d3WHvJGiyEp-zrHkSJm6E {
    margin-bottom: 31px;
  }
}
.Action-3IfrtYFVL_s18P6SMXss7a {
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .Action-3IfrtYFVL_s18P6SMXss7a {
    margin-bottom: 20px;
  }
}
@media (min-width: 1024px) {
  .Action-3IfrtYFVL_s18P6SMXss7a {
    margin-bottom: 25px;
  }
}
.Arrow-34Y3OsXJiL_fjiqO905TRN {
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  padding-bottom: 2px;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .Arrow-34Y3OsXJiL_fjiqO905TRN {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
    padding-bottom: 11px;
  }
}
@media (min-width: 1024px) {
  .Arrow-34Y3OsXJiL_fjiqO905TRN {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
    padding-bottom: 5px;
  }
}
.Notch-1JSxhZKGDgWKXDgWNIOYS- {
  position: absolute;
  z-index: 1;
  height: 35px;
  bottom: -35px;
  left: calc(50% - 66px);
}
@media (min-width: 768px) {
  .Notch-1JSxhZKGDgWKXDgWNIOYS- {
    height: 45px;
    bottom: -45px;
    left: calc(50% - 85px);
  }
}
@media (min-width: 1024px) {
  .Notch-1JSxhZKGDgWKXDgWNIOYS- {
    bottom: -50px;
    height: 50px;
    left: calc(50% - 94.5px);
  }
}
.Notch-1JSxhZKGDgWKXDgWNIOYS- > svg {
  display: block;
  height: 100%;
  width: auto;
}
.SuccessRate-1YKvwe1Kvbz4m16hlOVLQQ {
  padding-bottom: 10px;
}
.SuccessRateTitle-aZ4_OA7hDa4bELKyx4br1 {
  padding-left: 30px;
}
.TextContainer-NyJ0_WJV-j2eo4uv4eQ2g {
  margin-top: 10px;
}
@media (min-width: 1024px) {
  .TextContainer-NyJ0_WJV-j2eo4uv4eQ2g {
    margin-top: 15px;
  }
}
.NonBreakablePart-3zl4334fF0WDXcn-uU9kcK {
  white-space: nowrap;
}
.ButtonContainer-2oVhoGPNlDW5Z8lsY9yOZg {
  margin-top: 20px;
}
@media (min-width: 1024px) {
  .ButtonContainer-2oVhoGPNlDW5Z8lsY9yOZg {
    margin-top: 30px;
  }
}
.InfoTooltip-Wcd8P4M3JyRkjNus9j-8e {
  display: inline-block;
  white-space: nowrap;
  margin-left: 10px;
}
@media (min-width: 1024px) {
  .InfoTooltip-Wcd8P4M3JyRkjNus9j-8e svg {
    margin-bottom: 5px;
  }
}
.TheorySteps-3TZa4I_nyvEqBcrDU71RDR {
  padding: 48px 20px 0;
  text-align: center;
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .TheorySteps-3TZa4I_nyvEqBcrDU71RDR {
    padding: 68px 0 0;
  }
}
@media (min-width: 1024px) {
  .TheorySteps-3TZa4I_nyvEqBcrDU71RDR {
    padding: 100px 0 0;
  }
}
.StepsContainer-1FFLR1MvEKM4Gack1sd5N_ {
  position: relative;
  margin: 0 auto;
}
@media (min-width: 1024px) {
  .StepsContainer-1FFLR1MvEKM4Gack1sd5N_ {
    max-width: 1280px;
  }
}
.StepsPart2-3qbwBzRqiLgITnla9jcZ9W {
  margin-top: 48px;
}
@media (min-width: 1024px) {
  .StepsPart2-3qbwBzRqiLgITnla9jcZ9W {
    margin-top: 85px;
  }
}
.GirlStudentSofa-2Z2OAwScXRF8U2d9zJkgd9 {
  display: none;
}
.GirlConfetti-2JXtJVOVL9BGrGmsgtOZRL {
  display: block;
  margin: 30px auto;
  height: 266px;
}
@media (min-width: 768px) {
  .GirlConfetti-2JXtJVOVL9BGrGmsgtOZRL {
    height: 380px;
    margin: 50px auto;
  }
}
.InscriptionCTA-1_K13SHJk7lYZuoDlm3sYL {
  margin-top: 22px;
}
@media (min-width: 768px) {
  .InscriptionCTA-1_K13SHJk7lYZuoDlm3sYL {
    margin-top: 17px;
  }
}
@media (min-width: 1024px) {
  .InscriptionCTA-1_K13SHJk7lYZuoDlm3sYL {
    margin-top: 12px;
  }
}
@media (min-width: 1024px) {
  .StepsContainer-1FFLR1MvEKM4Gack1sd5N_ {
    padding-bottom: 100px;
  }
  .Line-19XKlgfsViS3KUNEGcgn19 {
    position: absolute;
    left: 50%;
    width: 16px;
    height: calc(100% - 100px);
    margin: 0 -8px;
    background: #eceff1;
    border-radius: 8px;
  }
  .StepsPart1-ONXWRI8YcbuVbsAzpBXDm {
    margin-left: 50%;
    width: 50%;
  }
  .StepsPart2-3qbwBzRqiLgITnla9jcZ9W {
    margin-right: 50%;
    width: 50%;
    padding-bottom: 70px;
  }
  .GirlStudentSofa-2Z2OAwScXRF8U2d9zJkgd9 {
    position: absolute;
    display: block;
    right: calc(50% + 92px);
    top: 0;
    bottom: 50%;
    height: 817px;
    direction: rtl;
  }
  .GirlConfetti-2JXtJVOVL9BGrGmsgtOZRL {
    position: absolute;
    margin: 0;
    left: calc(50% + 75px);
    bottom: 0;
    height: 951px;
  }
}
@media (min-width: 1440px) {
  .BlockContainer-2_S0gSBQnVmsZ1NwIHXBGR {
    width: 1280px;
    margin: 0 auto !important;
  }
}
.Guarantees-2B6iwIFDQFB3D5T1aKTIEo {
  padding: 0 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .Guarantees-2B6iwIFDQFB3D5T1aKTIEo {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 688px;
    margin: 0 auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
@media (min-width: 1024px) {
  .Guarantees-2B6iwIFDQFB3D5T1aKTIEo {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    max-width: none;
    margin: 0 80px;
  }
}
.Guarantee-C6ySq8FT-1dIja_Z7Ir0I {
  max-width: 450px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .Guarantee-C6ySq8FT-1dIja_Z7Ir0I {
    margin: 0;
    width: 324px;
  }
}
@media (min-width: 1024px) {
  .Guarantee-C6ySq8FT-1dIja_Z7Ir0I {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    max-width: 290px;
  }
}
.Guarantee-C6ySq8FT-1dIja_Z7Ir0I:not(:last-of-type) {
  margin-bottom: 40px;
}
@media (min-width: 1024px) {
  .Guarantee-C6ySq8FT-1dIja_Z7Ir0I:not(:last-of-type) {
    margin-bottom: 0;
    margin-right: 40px;
  }
}
@media (min-width: 768px) {
  .Guarantee-C6ySq8FT-1dIja_Z7Ir0I:not(:nth-child(-n + 2)) {
    margin-bottom: 0;
  }
}
.IconContainer-1EJsG0kbjWbpgdq5X1SgbM {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .IconContainer-1EJsG0kbjWbpgdq5X1SgbM {
    margin-bottom: 30px;
  }
}
.Title-3h5zejohaee6YWtn7XZrDO {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .Title-3h5zejohaee6YWtn7XZrDO {
    margin-bottom: 20px;
  }
}
/* UNUSED
.ToolTip-2Hl_YxAHeJLNzrntlt1W6u {
  display: inline-block;
}
@media (min-width: 768px) {
  .ToolTip-2Hl_YxAHeJLNzrntlt1W6u {
    display: block;
    margin: 0;
  }
}
@media (min-width: 1024px) {
  .ToolTip-2Hl_YxAHeJLNzrntlt1W6u {
    display: inline-block;
  }
}
*/
@media (min-width: 1024px) {
  .PackDescription-2qzsZtbRLdM13LR_FVEfFA {
    width: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.PackDescription-2qzsZtbRLdM13LR_FVEfFA:not(:first-child) {
  margin-top: 46px;
}
@media (min-width: 1024px) {
  .PackDescription-2qzsZtbRLdM13LR_FVEfFA:not(:first-child) {
    margin-top: 0;
  }
}
.Title-175pWGQAgx-AugT7H1ZR0F {
  text-align: center;
}
.SalesTextContainer-ULhOABbkcSjgrGHulYt0X {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .SalesTextContainer-ULhOABbkcSjgrGHulYt0X {
    margin-top: 20px;
  }
}
@media (min-width: 1024px) {
  .SalesTextContainer-ULhOABbkcSjgrGHulYt0X {
    margin-top: 33px;
  }
}
.SalesText-1UjkMhH_69UhT-GgpAnt43 {
  text-align: center;
}
@media (min-width: 1280px) {
  .SalesText-1UjkMhH_69UhT-GgpAnt43 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
  }
}
.SalesText-1UjkMhH_69UhT-GgpAnt43:not(:first-child) {
  margin-top: 6px;
}
@media (min-width: 768px) {
  .SalesText-1UjkMhH_69UhT-GgpAnt43:not(:first-child) {
    margin-top: 11px;
  }
}
.Checkmark-1DzJhu6l3XmsrYYYt9b3K2 {
  display: none;
}
@media (min-width: 1280px) {
  .Checkmark-1DzJhu6l3XmsrYYYt9b3K2 {
    display: block;
    margin-right: 18px;
    color: #ff6f00;
  }
}
.Step-1OkWDVa-sMy8eu9N8lxZUC {
  position: relative;
}
.Step-1OkWDVa-sMy8eu9N8lxZUC:not(:first-child) {
  margin-top: 48px;
}
@media (min-width: 1024px) {
  .Step-1OkWDVa-sMy8eu9N8lxZUC:not(:first-child) {
    margin-top: 85px;
  }
}
@media (min-width: 1024px) {
  .Content-30cHAqZ-gB-bE5uHaycO5V {
    margin: 0 auto;
    width: 400px;
  }
}
.BulletContainer-1nT2dafkbA6mVzCIQ_kZiO {
  display: none;
}
@media (min-width: 1024px) {
  .BulletContainer-1nT2dafkbA6mVzCIQ_kZiO {
    display: block;
    position: absolute;
    top: 0;
    width: 8px;
    line-height: 1;
  }
}
@media (min-width: 1024px) {
  .LeftBulletContainer-29zdLGzR-Gj3KVsUFFPb9o {
    right: -4px;
  }
}
@media (min-width: 1024px) {
  .RightBulletContainer-eL8fVVxjuXZpofLY8tdbp {
    left: -4px;
  }
}
.Bullet-2Kqd_4GUq6bzQEwsMhvRmX {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #b0bec5;
  border-radius: 8px;
}
@media (min-width: 1024px) {
  .StepNumber-1vcdnZc0UKptYaCouubgDB {
    line-height: 1;
  }
}
.Title-nu6Pqu-rf3Aq-ziNlUC0F {
  margin-top: 2px;
}
@media (min-width: 768px) {
  .Title-nu6Pqu-rf3Aq-ziNlUC0F {
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .Title-nu6Pqu-rf3Aq-ziNlUC0F {
    margin-top: 12px;
  }
}
.Description-3XlN4zVQ3wRX6Q1HZyJRQv {
  margin-top: 12px;
}
@media (min-width: 768px) {
  .Description-3XlN4zVQ3wRX6Q1HZyJRQv {
    margin: 10px auto 0;
    max-width: 540px;
  }
}
@media (min-width: 1024px) {
  .Description-3XlN4zVQ3wRX6Q1HZyJRQv {
    margin-top: 11px;
    max-width: none;
  }
}
.TheoryDescription-1aPcRv9ip4vanENExapmUZ {
  padding: 80px 20px;
}
@media (min-width: 768px) {
  .TheoryDescription-1aPcRv9ip4vanENExapmUZ {
    padding: 110px 0;
  }
}
@media (min-width: 1024px) {
  .TheoryDescription-1aPcRv9ip4vanENExapmUZ {
    padding: 120px 0 130px;
  }
}
@media (min-width: 768px) {
  .TheoryDescriptionContent-2RUm45jPquQdSMptIEyM-L {
    max-width: 335px;
    margin: 0 auto;
  }
}
@media (min-width: 1024px) {
  .TheoryDescriptionContent-2RUm45jPquQdSMptIEyM-L {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 1280px;
  }
  .TheoryDescriptionContent-2RUm45jPquQdSMptIEyM-L > :first-child {
    margin-right: 40px;
  }
}
@media (min-width: 1024px) {
  .PaddedTitle-3wo8rhjFy3AMdSzlAMnHll > :first-child {
    padding: 0 70px;
  }
}
.SeparatorStepsFormationType-nu4dLjmXUOTQYrdBBioYG {
  margin-top: 40px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .SeparatorStepsFormationType-nu4dLjmXUOTQYrdBBioYG {
    margin-top: 60px;
    margin-bottom: 70px;
  }
}
@media (min-width: 1024px) {
  .SeparatorStepsFormationType-nu4dLjmXUOTQYrdBBioYG {
    margin-top: 90px;
  }
}
@media (min-width: 1110px) {
  .SeparatorStepsFormationType-nu4dLjmXUOTQYrdBBioYG {
    margin-bottom: 100px;
  }
}
.SeparatorFormationTypeReviews-1p8TH7N7cM8jO-0SPyvfwk {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .SeparatorFormationTypeReviews-1p8TH7N7cM8jO-0SPyvfwk {
    margin-top: 70px;
    margin-bottom: 80px;
  }
}
@media (min-width: 1110px) {
  .SeparatorFormationTypeReviews-1p8TH7N7cM8jO-0SPyvfwk {
    margin-top: 100px;
    margin-bottom: 160px;
  }
}
.Banner-3hN1t7RTjd00v_Ksl7CUUB {
  padding: 58px 20px;
}
@media (min-width: 480px) {
  .Banner-3hN1t7RTjd00v_Ksl7CUUB {
    padding: 60px 20px;
  }
}
@media (min-width: 768px) {
  .Banner-3hN1t7RTjd00v_Ksl7CUUB {
    padding: 56px 40px 60px;
  }
}
@media (min-width: 768px) and (max-width: 885px) {
  .Banner-3hN1t7RTjd00v_Ksl7CUUB {
    padding: 60px 40px;
  }
}
.Content-r2p6YjoO6JTz1oGSZCj7- {
  margin: auto;
  text-align: center;
}
@media (min-width: 480px) {
  .Content-r2p6YjoO6JTz1oGSZCj7- {
    max-width: 550px;
  }
}
@media (min-width: 1024px) {
  .Content-r2p6YjoO6JTz1oGSZCj7- {
    max-width: 850px;
  }
}
.Container-2eEOBYzvvYJeSH8Zo3CWn0 {
  text-align: center;
}
@media (min-width: 768px) {
  .Container-2eEOBYzvvYJeSH8Zo3CWn0 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (min-width: 1110px) {
  .Container-2eEOBYzvvYJeSH8Zo3CWn0 {
    position: relative;
    display: block;
    height: 636px;
    text-align: left;
  }
}
.TitleContainer-18z1tIOxM3nbm7f7xSYzKP {
  margin: 0 auto;
}
@media (min-width: 1110px) {
  .TitleContainer-18z1tIOxM3nbm7f7xSYzKP {
    height: 378px;
    position: relative;
  }
}
.Title-2jkmyGmHlUGOdhNMWLMRmh {
  max-width: 300px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .Title-2jkmyGmHlUGOdhNMWLMRmh {
    max-width: 400px;
  }
}
@media (min-width: 1110px) {
  .Title-2jkmyGmHlUGOdhNMWLMRmh {
    max-width: 300px;
    position: absolute;
    bottom: 25px;
  }
}
.CardsContainer-1xC44xZk0fjM73gMyZlTeK {
  margin-top: 32px;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .CardsContainer-1xC44xZk0fjM73gMyZlTeK {
    width: 620px;
    margin: 12px auto 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
@media (min-width: 1110px) {
  .CardsContainer-1xC44xZk0fjM73gMyZlTeK {
    position: absolute;
    margin: 0;
    top: 0;
    right: 80px;
    bottom: 0;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (min-width: 1440px) {
  .CardsContainer-1xC44xZk0fjM73gMyZlTeK {
    right: 0;
  }
}
.ButtonContainer-2RjOxacqeydRoEvUR4YPBM {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .ButtonContainer-2RjOxacqeydRoEvUR4YPBM {
    margin-top: 50px;
  }
}
@media (min-width: 1110px) {
  .ButtonContainer-2RjOxacqeydRoEvUR4YPBM {
    height: 255px;
  }
}
@media (min-width: 1110px) {
  .ButtonContainer-2RjOxacqeydRoEvUR4YPBM,
  .TitleContainer-18z1tIOxM3nbm7f7xSYzKP {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 110px;
  }
}
.PermisBContainer-29vyWeMNPDDN2L6Ld-_3BK {
  top: 0;
  margin-top: 40px;
}
@media (min-width: 768px) {
  .PermisBContainer-29vyWeMNPDDN2L6Ld-_3BK {
    top: 40px;
    max-width: 100% !important;
    margin-bottom: 40px;
  }
}
@media (min-width: 1110px) {
  .PermisBContainer-29vyWeMNPDDN2L6Ld-_3BK {
    top: 160px;
    max-width: 290px !important;
    margin: 0 40px 100px 0;
  }
}
.PermisAnnuleIllustration-3soW3ZbpyS_sDDQ_rvAtdb,
.PermisBIllustration-28ZjX0fZtLU7-gEUsBl5sw {
  margin-top: 25px !important;
}
@media (min-width: 768px) {
  .PermisAnnuleIllustration-3soW3ZbpyS_sDDQ_rvAtdb,
  .PermisBIllustration-28ZjX0fZtLU7-gEUsBl5sw {
    margin-top: 40px !important;
  }
}
.PermisEtrangerIllustration-Vt2RaqIWL6FmF8V5TfZ8m {
  margin-top: 45px !important;
  margin-bottom: 25px !important;
  height: auto !important;
  width: 149px !important;
}
@media (min-width: 768px) {
  .PermisEtrangerIllustration-Vt2RaqIWL6FmF8V5TfZ8m {
    margin-top: 60px !important;
    height: auto !important;
    width: 179px !important;
  }
}
.PermisBPatternPosition-3QSnrGnc9ckJ-aw6p6tvNS {
  background-position: -859px -830px;
}
.PermisAnnulePatternPosition-2-h3PYfSYcwTYxmEP1xnUW {
  background-position: -3480px -920px;
}
.PermisEtrangerPatternPosition-1v-9jv-fIrVcPT2_2y7I82 {
  background-position: -80px -140px;
}
.Card-3gs_c7Wcs7AIzWc_DpUEkK {
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  padding: 0 20px 0 27px;
  border-radius: 20px;
  text-align: center;
  width: 100%;
  height: 216px;
}
@media (min-width: 768px) {
  .Card-3gs_c7Wcs7AIzWc_DpUEkK {
    margin-top: 40px;
    padding: 0 30px;
    max-width: 290px;
    height: 278px;
  }
}
@media (min-width: 1110px) {
  .Card-3gs_c7Wcs7AIzWc_DpUEkK {
    padding: 0 40px;
    text-align: left;
  }
  .Card-3gs_c7Wcs7AIzWc_DpUEkK:nth-child(odd) {
    margin-top: 0;
  }
}
.Illustration-2DTjAbCZmzXJXoBmdmszGt {
  display: block;
  margin: 0 auto;
  height: 80px;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .Illustration-2DTjAbCZmzXJXoBmdmszGt {
    height: auto;
    width: 150px;
  }
}
@media (min-width: 1110px) {
  .Illustration-2DTjAbCZmzXJXoBmdmszGt {
    margin-left: 0;
    margin-right: 0;
  }
}
.Title-61oAcjKO4SqGEY98sDVmZ {
  margin-top: 17px;
}
@media (min-width: 768px) {
  .Title-61oAcjKO4SqGEY98sDVmZ {
    position: absolute;
    bottom: 45px;
    left: 40px;
    right: 40px;
    margin: 0;
    text-align: center;
  }
}
@media (min-width: 1110px) {
  .Title-61oAcjKO4SqGEY98sDVmZ {
    text-align: left;
  }
}
.Orange-2IFrLMhuSFjRuD1DyEvtK4 {
  background: -webkit-linear-gradient(315deg, #ffab00, #ff6f00);
  background: -o-linear-gradient(315deg, #ffab00 0, #ff6f00 100%);
  background: linear-gradient(-225deg, #ffab00, #ff6f00);
}
.Purple-uVAx1T_1IS43E2QtjoTE_ {
  background: -webkit-linear-gradient(315deg, #a200f2, #651fff);
  background: -o-linear-gradient(315deg, #a200f2 0, #651fff 100%);
  background: linear-gradient(-225deg, #a200f2, #651fff);
}
.Blue-21NDjaXL7qy8cPLto75A9z {
  background: -webkit-linear-gradient(315deg, #009dff, #2979ff);
  background: -o-linear-gradient(315deg, #009dff 0, #2979ff 100%);
  background: linear-gradient(-225deg, #009dff, #2979ff);
}
.Separator-gXnlJ7aV5geUWgAhow-no {
  margin-top: 43px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .Separator-gXnlJ7aV5geUWgAhow-no {
    margin-top: 63px;
    margin-bottom: 65px;
  }
}
@media (min-width: 1024px) {
  .Separator-gXnlJ7aV5geUWgAhow-no {
    margin-top: 160px;
    margin-bottom: 147px;
  }
}

.MediaReassurance-10eoWQ4bFtZg58xnkZSmGe {
  padding: 45px 20px 55px;
}

@media (min-width: 768px) {
  .MediaReassurance-10eoWQ4bFtZg58xnkZSmGe {
    padding: 70px 40px;
  }
}

@media (min-width: 1024px) {
  .MediaReassurance-10eoWQ4bFtZg58xnkZSmGe {
    padding: 115px 80px 100px;
  }
}

.MediaReassurance-10eoWQ4bFtZg58xnkZSmGe input {
  display: none;
}

.Carousel-1PVTWMaq783WNjlL_tZAf6 {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .Carousel-1PVTWMaq783WNjlL_tZAf6 {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    max-width: 1280px;
    margin: 0 auto;
  }
}

.ReassuranceItem-1ArNnIfWIJ6EeQfzjC8dyh {
  text-align: center;
}

.ReassuranceItem-1ArNnIfWIJ6EeQfzjC8dyh:not(:last-of-type) {
  margin-right: 40px;
}

@media (min-width: 1024px) {
  .ReassuranceItem-1ArNnIfWIJ6EeQfzjC8dyh:not(:last-of-type) {
    margin-right: 0;
  }
}

.ReassuranceItem-1ArNnIfWIJ6EeQfzjC8dyh img {
  margin-top: 120px;
  opacity: 0.3;
  -webkit-transition: opacity 0.8s ease-out;
  -o-transition: opacity 0.8s ease-out;
  transition: opacity 0.8s ease-out;
}

@media (min-width: 1024px) {
  .ReassuranceItem-1ArNnIfWIJ6EeQfzjC8dyh img {
    margin-top: 0;
    opacity: 1;
  }
}

.ReassuranceText-2SCfFDzN57-2N6TxBxKbR4 {
  position: absolute;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100px;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-out;
  -o-transition: opacity 0.4s ease-out;
  transition: opacity 0.4s ease-out;
}

@media (min-width: 1024px) {
  .ReassuranceText-2SCfFDzN57-2N6TxBxKbR4 {
    position: static;
    display: block;
    min-height: 0;
    opacity: 1;
  }
}

.ReassuranceText-2SCfFDzN57-2N6TxBxKbR4 > :first-child {
  display: table-cell;
  vertical-align: middle;
}

@media (min-width: 1024px) {
  .ReassuranceText-2SCfFDzN57-2N6TxBxKbR4 > :first-child {
    display: block;
  }
}

.Radio-3n5yU7I1fe3FTBfHgZr13z:checked
  + .ReassuranceItem-1ArNnIfWIJ6EeQfzjC8dyh
  > .ReassuranceText-2SCfFDzN57-2N6TxBxKbR4 {
  opacity: 1;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.Radio-3n5yU7I1fe3FTBfHgZr13z:checked
  + .ReassuranceItem-1ArNnIfWIJ6EeQfzjC8dyh
  img {
  opacity: 1;
}

/* @TODO: change name for true icon */
@media (min-width: 1024px) {
  .France3Item-BzCJauMiIFoJB_LJaoGA1 {
    max-width: 310px;
  }

  .France3Item-BzCJauMiIFoJB_LJaoGA1 img {
    margin: 22px auto 0;
  }
}

@media (min-width: 1024px) {
  .OuestFranceItem-qljbEcCb91gVjSWybUkdQ {
    max-width: 290px;
  }

  .OuestFranceItem-qljbEcCb91gVjSWybUkdQ img {
    margin: 26px auto 0;
  }
}

@media (min-width: 1024px) {
  .BfmItem-JgvG06LaBAqMQeGqh5b8A {
    max-width: 290px;
    padding: 0 45px;
  }

  .BfmItem-JgvG06LaBAqMQeGqh5b8A img {
    margin: 26px auto 0;
  }
}

.France3Svg-aDTBRgGouPdzy30rFj-oJ {
  width: 37px;
  height: 32px;
}

@media (min-width: 768px) {
  .France3Svg-aDTBRgGouPdzy30rFj-oJ {
    width: 59px;
    height: 50px;
  }
}

.BfmSvg-1yVo94m9xpPjHW1kiOQBGl {
  width: 37px;
  height: 37px;
}

@media (min-width: 768px) {
  .BfmSvg-1yVo94m9xpPjHW1kiOQBGl {
    width: 60px;
    height: 60px;
  }
}

.OuestFranceSvg-u_msRh2KKGVF1OZvDytTF {
  width: 93px;
  height: 32px;
}

@media (min-width: 768px) {
  .OuestFranceSvg-u_msRh2KKGVF1OZvDytTF {
    width: 147px;
    height: 50px;
  }
}


























/*! CSS Used from: https://assets.ornikar.com/learner-app/static/css/64.a806fe0c.chunk.css */

.styles-module_LabelContainer__1_BEJ {
  padding-bottom: 6px;
}
.styles-module_DefaultStyling__jYFJ9 {
  padding: 5px 0 10px;
}
@media (min-width: 480px) {
  .styles-module_DefaultStyling__jYFJ9 {
    padding: 10px 0;
  }
}
.styles-module_FantomHeader__1-efR {
  z-index: 3;
  position: relative;
  min-height: 60px;
}
@media (min-width: 480px) {
  .styles-module_FantomHeader__1-efR {
    min-height: 80px;
  }
}


/*! CSS Used from: https://assets.ornikar.com/learner-app/static/css/main.4357f181.chunk.css */
.styles_NotificationContainer__g_mAg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
}
p {
  margin: 0;
  padding: 0;
}
.styles_Logo__2zXDu {
  width: auto;
  fill: #252a2d;
}
.styles_variant-header-small__1DrCJ {
  height: 24px;
}
@media (min-width: 480px) {
  .styles_variant-header-small__1DrCJ {
    height: 28px;
  }
}
/*! CSS Used from: https://assets.ornikar.com/learner-app/static/css/authentication.c1939a6f.chunk.css */
.styles_Pattern__1UaTi:before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background-color: #fafbfc;
}
@media (min-width: 480px) {
  .styles_Pattern__1UaTi:before {
    background-image: url(https://assets.ornikar.com/learner-app/static/media/pattern.raw.5df3b401.svg);
    background-position: center top 3433px;
  }
}
/*! CSS Used from: https://assets.ornikar.com/learner-app/static/css/cart.de405cf1.chunk.css */
.styles_Be2BillInputContainer__VRsJY {
  height: 36px;
}
.styles_PaymentNotification__1s7s1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .styles_PaymentNotification__1s7s1 {
    position: fixed;
  }
}
@media (min-width: 768px) {
  .styles_Label__1vfQh {
    padding-top: 5px;
  }
}
.styles_PaymentSecureContainer__IQNhA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.styles_PaymentSecureContainer__IQNhA > svg {
  margin: -1px 10px 0 0;
}
.styles_FormBottom__27EhF {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 10px;
}
.styles_FormBottom__27EhF > * {
  width: calc(50% - 10px);
  margin-right: 20px;
}
.styles_FormBottom__27EhF > :last-of-type {
  margin-right: 0;
}
@media (min-width: 1024px) {
  .styles_FormBottom__27EhF > :first-child {
    width: 160px;
  }
}
@media (min-width: 1024px) {
  .styles_FormBottom__27EhF > :nth-child(2) {
    width: 130px;
  }
}
.styles_PaymentSecure__-_76G {
  display: none;
}
@media (min-width: 1024px) {
  .styles_PaymentSecure__-_76G {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.styles_Optins__d_CVM {
  margin-bottom: 15px;
}
@media (min-width: 480px) {
  .styles_Optins__d_CVM {
    margin-top: 10px;
  }
}
@media (min-width: 768px) {
  .styles_Optins__d_CVM {
    margin: -12px 0 0;
  }
}
@media (min-width: 1024px) {
  .styles_Optins__d_CVM > :last-child {
    margin-top: -10px;
  }
}
.styles_Action__1Gs6J {
  margin-top: 4px;
}
@media (min-width: 768px) {
  .styles_Action__1Gs6J {
    margin-top: 10px;
  }
}
.styles_Hidden__31nCN {
  display: none;
}
.styles_CardWithBrand__3S-CG {
  position: relative;
}
.styles_Pattern__1UaTi:before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background-color: #fafbfc;
}
@media (min-width: 480px) {
  .styles_Pattern__1UaTi:before {
    background-image: url(https://assets.ornikar.com/learner-app/static/media/pattern.raw.5df3b401.svg);
    background-position: center top 3433px;
  }
}
.styles_Content__1SQHM {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.styles_Logo__30_V_ {
  display: block;
  height: 22px;
  -webkit-transform: translate(10px, -1px);
  transform: translate(10px, -1px);
}
@media (min-width: 480px) {
  .styles_Logo__30_V_ {
    height: 28px;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}
.styles_CancelButtons__2ck2s > :first-child {
  display: none;
}
@media (min-width: 480px) {
  .styles_CancelButtons__2ck2s > :first-child {
    display: inline-block;
  }
}
.styles_CancelButtons__2ck2s > :last-child {
  display: inline-block;
}
@media (min-width: 480px) {
  .styles_CancelButtons__2ck2s > :last-child {
    display: none;
  }
}
@media (min-width: 1024px) {
  .styles_ContentContainer__1gz-m {
    width: calc(100% - 400px);
    max-width: 550px;
    padding-right: 40px;
    margin-top: 6px;
  }
}
@media (min-width: 1024px) {
  .styles_FormContainer__1-z_R {
    max-width: 400px;
  }
}
.styles_Page__Y4-j3 {
  padding: 40px 20px;
}
@media (min-width: 480px) {
  .styles_Page__Y4-j3 {
    padding: 76px 40px 40px;
  }
}
@media (min-width: 1024px) {
  .styles_Page__Y4-j3 {
    padding-top: 60px;
  }
}
@media (min-width: 1024px) {
  .styles_PageContainer__1H8Ki {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1060px;
    margin: auto;
  }
}
.styles_LogoContainer__3dR1A {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 215px;
  width: 100%;
  margin: 30px auto 0;
}
@media (min-width: 1024px) {
  .styles_LogoContainer__3dR1A {
    max-width: 240px;
  }
}
.styles_Illustration__18jLv {
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.styles_Visa__2AHcY > svg {
  height: 18px;
}
@media (min-width: 1024px) {
  .styles_Visa__2AHcY > svg {
    height: 23px;
  }
}
.styles_MasterCard__3_CbE > svg {
  height: 27px;
}
@media (min-width: 1024px) {
  .styles_MasterCard__3_CbE > svg {
    height: 35px;
  }
}
.styles_Cb__3TY5t > svg {
  height: 24px;
}
@media (min-width: 1024px) {
  .styles_Cb__3TY5t > svg {
    height: 31px;
  }
}
.styles_Card__prtwC {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: auto;
  overflow: visible;
  padding: 40px 50px 20px;
}
.styles_Reassurance__1Ddk6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (min-width: 1024px) {
  .styles_Reassurance__1Ddk6 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
.styles_Text__3Sn1V {
  text-align: center;
  margin-bottom: 25px;
}
@media (min-width: 1024px) {
  .styles_Text__3Sn1V {
    text-align: left;
    margin: 20px 0 0;
  }
}
.styles_Logos__hqaJm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  max-width: 310px;
  margin: 0 auto;
}
@media (min-width: 1024px) {
  .styles_Logos__hqaJm {
    margin: 0;
  }
}
.styles_MasterCard__133ax svg {
  max-height: 29px;
}
.styles_Visa__3hHhF svg {
  max-height: 35px;
}
.styles_Pci__38O7r svg {
  max-height: 33px;
}
.styles_Illustration__3BiV_ {
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.styles_Container__3iSeo {
  padding: 18px 20px 22px;
  max-width: 100%;
  overflow: visible;
}
.styles_UpsellText__3Ccxc {
  text-align: left;
  max-width: 300px;
}
.styles_Highlight__5v-Sc {
  font-weight: 700;
}
.styles_HighlightPrimary__bxjJ4 {
  font-weight: 700;
  color: #ff6f00;
}
.styles_Content__3heCT {
  text-align: center;
  margin: auto;
}
@media (min-width: 1024px) {
  .styles_Content__3heCT {
    text-align: left;
  }
}
.styles_Options__RHGIO {
  width: 100%;
  max-width: 480px;
  margin: auto;
}
@media (min-width: 1024px) {
  .styles_Options__RHGIO {
    max-width: 100%;
  }
}
.styles_Title__h1Kcp {
  margin-bottom: 18px;
}
@media (min-width: 480px) {
  .styles_Title__h1Kcp {
    margin-bottom: 14px;
  }
}
@media (min-width: 1024px) {
  .styles_Title__h1Kcp {
    margin-bottom: 15px;
  }
}
.styles_Description__zIzcq {
  width: 100%;
  max-width: 480px;
  margin: auto;
  text-align: center;
}
@media (min-width: 1024px) {
  .styles_Description__zIzcq {
    text-align: left;
    max-width: 100%;
  }
}
.styles_Coupon__2RVRj {
  margin-top: 15px;
}
@media (min-width: 480px) {
  .styles_Coupon__2RVRj {
    margin-top: 10px;
  }
}
@media (min-width: 1024px) {
  .styles_Coupon__2RVRj {
    margin-top: 12px;
  }
}
.styles_Upsell__2Sy-r {
  margin-top: 30px;
}
.styles_Reassurance__2yZjY {
  margin-top: 95px;
}
